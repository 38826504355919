import React, { useRef, useState, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import ptBR from 'date-fns/locale/pt-BR';
import { DayPicker, DayModifiers } from 'react-day-picker';
// import 'react-day-picker/lib/style.css';
import { debounce, maxBy } from 'lodash';
import { format } from 'date-fns';
import * as Yup from 'yup';

import { FiUser } from 'react-icons/fi';
import { IoPhonePortraitOutline } from 'react-icons/io5';
import { FaBirthdayCake } from 'react-icons/fa';

import { useEffect } from 'react';
import Swal from 'sweetalert2';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Select from '../../components/Select';
import { Form, Footer } from '../../components/Form';
import Input from '../../components/Input';
import InputMask from '../../components/InputMask';
import Switch from '../../components/Switch';
import { useToast } from '../../hooks/toast';

import ScheduleComponent from './ScheduleComponent';
import {
  Container,
  Header,
  Calendar,
  ModalNewAppointmentContainer,
  ContainerItemSelect,
  ProviderItem,
  ServiceItem,
  ServiceContainerItems,
  HourItem,
  ContainerUser,
  UserItem,
  ContainerSelectUser,
  UserSelectItem,
  ModalBlockHourContainer,
} from './styles';

import api from '../../services/api';
import { Spin } from '../../components/Spin';
import getValidationErrors from '../../utils/getValidationErrors';

interface IProvider {
  id: string;
  text: string;
  color: string;
  avatar_url: string;
}

interface IService {
  id: number;
  name: string;
  duration: number;
}

interface AppointmentSchedule {
  title: string;
  provider_name: string;
  startDate: Date;
  endDate: Date;
  location: string;
  provider: string;
  teste?: string;
  user_avatar: string;
  status_name: string;
  status_color: string;
  services: any;
  id: string;
  phone?: string;
}

interface IUser {
  id: string;
  username: string;
  phone: string;
  avatar_url: string;
}

export default function Schedule(): JSX.Element {
  const formRef = useRef<FormHandles>(null);
  const formNewAppointmentRef = useRef<FormHandles>(null);
  const formSearchUser = useRef<FormHandles>(null);
  const formCreateUserRef = useRef<FormHandles>(null);
  const formBlockHourRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [providers, setProviders] = useState<IProvider[]>([]);
  const [allServices, setAllServices] = useState<IService[]>([]);
  const [appointments, setAppointments] = useState<AppointmentSchedule[]>([]);
  const [bloquedTimes, setBloquedTimes] = useState<AppointmentSchedule[]>([]);
  const [modalNewAppointment, setModalNewAppointment] = useState(false);
  const [modalSelectUser, setModalSelectUser] = useState(false);

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    new Date(),
  );
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [providerSelectedId, setProviderSelectedId] = useState<string | null>(
    null,
  );
  const [servicesSelected, setServicesSelected] = useState<IService[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [userSelected, setUserSelected] = useState<IUser>({} as IUser);
  const [usersSearch, setUsersSearch] = useState<IUser[]>([]);
  const [disabledDays, setDisabledDays] = useState<any>([]);
  const [hours, setHours] = useState<any>([]);
  const [hourSelected, setHourSelected] = useState<any>({});
  const [dateSelected, setDateSelected] = useState<Date>(new Date());
  const [loading, setLoading] = useState(false);
  const [modalCreateUser, setModalCreateUser] = useState(false);
  const [modalBlockHour, setModalBlockHour] = useState(false);

  const handleMonthChange = useCallback(
    (date: Date) => {
      listDaysAvailable({
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      });
      setCurrentMonth(date);
    },
    [providerSelectedId],
  );

  const handleDateChange = useCallback(
    (date: Date, modifiers: DayModifiers) => {
      if (modifiers.available && !modifiers.disabled) {
        listHoursAvailable({
          month: date.getMonth() + 1,
          year: date.getFullYear(),
          day: date.getDate(),
        });
        setDateSelected(date);
        setSelectedDate(date);
      }
    },
    [providerSelectedId],
  );

  async function loadProviders(): Promise<void> {
    try {
      setLoading(true);
      const response = await api.get('/provider');
      if (response.data) {
        const array = response.data.map((item: any) => ({
          id: item.id,
          // name: item.username,
          text: item.username,
          color: '#fff',
          avatar_url: item.avatar_url,
        }));
        setProviders(array);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  async function loadServices(): Promise<void> {
    try {
      setLoading(true);
      const response = await api.get('/services');

      setAllServices(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  async function loadAppointments(): Promise<void> {
    try {
      setLoading(true);
      const response = await api.get(`/provider/appointments`, {
        params: {
          startDate: '2022-06-01 00:00:00',
          endDate: '2022-12-31 23:59:59',
        },
      });

      if (response.data) {
        const array = response.data?.map((item: any) => {
          const endDateAppointment = new Date(item.date);
          endDateAppointment.setMinutes(item.duration);

          let services = '';
          item.services?.forEach((service: any) => {
            services =
              services.length > 0
                ? `${services}, ${service.name}`
                : service.name;
          });

          return {
            title: item.user?.username,
            startDate: item.date,
            endDate: endDateAppointment,
            location: 'Allan Hebert Barber Shop',
            provider_name: item.provider.username,
            // roomId: Math.floor(Math.round(10)),
            provider: item.provider.id,
            // teste: 'vrau',
            user_avatar: item.user.avatar_url,
            status_name: item.status?.name || 'Sem Status',
            status_color: item.status.color,
            services,
            id: item.id,
            phone: item.user?.phone || '',
          };
        });

        setAppointments(array);
      }

      console.log(response);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  async function loadUsers(): Promise<void> {
    try {
      setLoading(true);
      const response = await api.get('/users');
      setUsers(response.data);
      setUsersSearch(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  async function handleSelectedProvider(data: any): Promise<void> {
    try {
      // loadAppointments(data.providerId);
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  }

  function validateItemsSelected(): boolean {
    if (!userSelected.id) {
      addToast({
        type: 'error',
        title: '',
        description: 'É necessário selecionar o usuário que sera atendido',
      });
      return false;
    }
    if (!providerSelectedId) {
      addToast({
        type: 'error',
        title: '',
        description: 'É necessário selecionar o prestador do serviço',
      });
      return false;
    }
    if (!servicesSelected.length) {
      addToast({
        type: 'error',
        title: '',
        description: 'É necessário selecionar pelo menos 1 serviço.',
      });
      return false;
    }
    if (!dateSelected) {
      addToast({
        type: 'error',
        title: '',
        description: 'É necessário selecionar uma data de agendamento!',
      });
      return false;
    }

    if (!hourSelected.hour) {
      addToast({
        type: 'error',
        title: '',
        description: 'É necessário selecionar uma hora para o agendamento!',
      });
      return false;
    }

    return true;
  }

  async function handleNewAppointment(): Promise<void> {
    try {
      const isValid = validateItemsSelected();
      if (isValid) {
        setLoading(true);
        const dateAppointment = dateSelected;

        dateAppointment.setHours(hourSelected.hour);
        dateAppointment.setMinutes(hourSelected.minute);

        const response = await api.post('appointments', {
          provider_id: providerSelectedId,
          date: dateAppointment,
          services: servicesSelected,
          user_id: userSelected.id,
        });

        if (response.data) {
          const endDateAppointment = new Date(response.data.date);
          endDateAppointment.setMinutes(response.data.duration);

          let services = '';
          response.data.services?.forEach((service: any) => {
            services =
              services.length > 0
                ? `${services}, ${service.name}`
                : service.name;
          });

          setAppointments([
            ...appointments,
            {
              title: response.data.user?.username,
              startDate: response.data.date,
              endDate: endDateAppointment,
              location: 'Allan Hebert Barber Shop',
              provider_name: response.data.provider.username,
              provider: response.data.provider.id,
              teste: 'vrau',
              user_avatar: response.data.user.avatar_url,
              status_name: response.data.status?.name || 'Sem Status',
              status_color: response.data.status.color,
              id: response.data.id,
              services,
            },
          ]);
        }

        addToast({
          type: 'success',
          title: '',
          description: ' Agendamento realizado com sucesso!',
        });
        setProviderSelectedId(null);
        setServicesSelected([]);
        setHourSelected({});
        setModalNewAppointment(false);
        loadAppointments();
      }
      // listHoursAvailable({ year: date.year, month: date.month, day: date.day });
    } catch (err: any) {
      console.log(err);
      addToast({
        type: 'error',
        title: 'Houve um erro ao processar sua solicitação.',
        description: err.data.message || 'Erro de solicitação',
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmitUser(data: any): Promise<void> {
    try {
      console.log(data);
    } catch (err) {
      console.error(err);
    }
  }

  async function listDaysAvailable({ month, year }: any): Promise<void> {
    try {
      setLoading(true);
      const response = await api.get(
        `/provider/${providerSelectedId}/month-availability`,
        {
          params: {
            year,
            month,
          },
        },
      );
      console.log(response);

      const daysNotAvailable = response.data
        .filter(({ day, available }: any) => !available)
        .map(({ day, available }: any) => ({
          to: new Date(year, month, day),
          from: new Date(year, month, day),
        }));

      setDisabledDays(daysNotAvailable);
    } catch (err) {
      console.error(err.data.message);
    } finally {
      setLoading(false);
    }
  }

  async function listBloquedTimes(): Promise<void> {
    try {
      setLoading(true);
      const response = await api.post('/appointments/bloquedTimes');

      const arrayBloqued = response.data.map((element: any) => ({
        title: 'Bloqueado',
        startDate: element.startDate,
        endDate: element.endDate,
        location: 'Allan Hebert Barber Shop',
        // provider_name: response.data.provider.username,
        provider: element.provider_id,
        user_avatar: '',
        status_name: response.data.status?.name || 'Sem Status',
        status_color: '#dc3545',
        id: element.id,
        services: element.reason,
        phone: '',
      }));

      console.log('bloqueios', arrayBloqued);

      setBloquedTimes(arrayBloqued);
      console.log('bloqueios', response);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  const listHoursAvailable = useCallback(
    async ({ year, month, day }) => {
      try {
        setLoading(true);
        const response = await api.get(
          `/provider/${providerSelectedId}/day-availability`,
          {
            params: {
              year,
              month,
              day,
              timeService: maxBy(servicesSelected, 'duration')?.duration,
              isProvider: true,
            },
          },
        );
        const hourAvailable = response.data.map(
          ({ hour, minute, available, bloqued }: any) => ({
            hour,
            minute,
            available,
            bloqued,
            hourFormatted: format(new Date().setHours(hour, minute), 'HH:mm'),
          }),
        );
        setHours(hourAvailable);
      } catch (err) {
        console.error();
      } finally {
        setLoading(false);
      }
    },
    [providerSelectedId, servicesSelected],
  );

  const handleSearchUser = useCallback(
    debounce((value: string) => {
      if (value === '') {
        setUsersSearch(users);
      } else {
        setUsersSearch(
          users.filter(
            user =>
              user.username.toLowerCase().includes(value.toLowerCase()) ||
              user.phone.toLowerCase().includes(value.toLowerCase()),
          ),
        );
      }
    }, 500),
    [users, usersSearch],
  );

  async function handleCreateUser(data: any): Promise<void> {
    try {
      setLoading(true);
      formCreateUserRef.current?.setErrors({});

      const [day, month, year] = data?.dateBirth?.split('/');

      const dateBirth = new Date(year, month - 1, day);

      const schema = Yup.object().shape({
        username: Yup.string().required('Campo obrigatório'),
        phone: Yup.string().required('Campo obrigatório'),
      });

      await schema.validate(data, { abortEarly: false });

      const response = await api.post('/users', {
        ...data,
        isProvider: false,
        isAdmin: false,
        dateBirth,
      });

      addToast({
        title: 'Novo usuário criado com sucesso',
        type: 'success',
      });

      setUsersSearch([response.data, ...usersSearch]);

      setModalCreateUser(false);
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      }

      addToast({
        title:
          err?.data?.message || 'Houve um erro ao tentar criar um usuários',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  function validateFieldsToBlockHour(data: any): boolean {
    if (!providerSelectedId) {
      addToast({
        type: 'error',
        title: 'Aah não!',
        description:
          'É necessário selecionar o prestador para efetuar o bloqueio',
      });
      return false;
    }

    if (!dateSelected) {
      addToast({
        type: 'error',
        title: 'Aah não!',
        description: 'É necessário selecionar a data para efetuar o bloqueio',
      });
      return false;
    }

    if (!data.startHour && !data.endHour) {
      addToast({
        type: 'error',
        title: 'Aah não!',
        description:
          'É necessário informar a hora de inicio e de fim do bloqueio',
      });
      return false;
    }
    return true;
  }

  async function handleCreateBlockHour(data: any): Promise<void> {
    try {
      const isValid = validateFieldsToBlockHour(data);
      if (isValid) {
        formBlockHourRef.current?.setErrors({});

        const schema = Yup.object().shape({
          startHour: Yup.string().required('Campo obrigatório'),
          endHour: Yup.string().required('Campo obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        const [startHour, startMinute] = data.startHour.split(':');
        const [endHour, endMinute] = data.endHour.split(':');

        console.log(startHour, startMinute);
        console.log(endHour, endMinute);

        const startDate = new Date(dateSelected);
        startDate.setHours(startHour);
        startDate.setMinutes(startMinute);
        startDate.setSeconds(0);
        startDate.setMilliseconds(0);
        console.log(startDate, dateSelected);

        const endDate = dateSelected;
        endDate.setHours(endHour);
        endDate.setMinutes(endMinute);
        endDate.setSeconds(0);
        endDate.setMilliseconds(0);

        console.log(startDate, endDate);

        setLoading(true);

        const response = await api.post('/provider/bloqued', {
          startDate,
          endDate,
          reason: data.reason,
          provider_id: providerSelectedId,
        });

        setBloquedTimes([
          ...bloquedTimes,
          {
            title: 'Bloqueado',
            startDate: response.data.startDate,
            endDate: response.data.endDate,
            location: 'Allan Hebert Barber Shop',
            provider_name: '',
            provider: response.data.provider_id,
            user_avatar: '',
            status_name: response.data.status?.name || 'Sem Status',
            status_color: '#dc3545',
            id: response.data.id,
            services: response.data.reason,
          },
        ]);

        setModalBlockHour(false);
        setSelectedDate(new Date());
        setProviderSelectedId(null);
      }
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formBlockHourRef.current?.setErrors(errors);
      }

      addToast({
        title:
          err?.data?.message || 'Houve um erro ao tentar criar um usuários',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  // useEffect(() => {
  //   if (servicesSelected.length > 0) {
  //     console.table(servicesSelected);
  //   }
  // }, [servicesSelected]);

  useEffect(() => {
    loadAppointments();
    loadProviders();
    loadServices();
    loadUsers();
    listBloquedTimes();
  }, []);

  return (
    <Container>
      <Spin isVisible={loading}>
        <Modal
          size={1000}
          title="Novo agendamento"
          isOpen={modalNewAppointment}
          onRequestClose={() => {
            setModalNewAppointment(false);
            setProviderSelectedId(null);
            setServicesSelected([]);
            setHourSelected({});
            setUserSelected({} as IUser);
          }}
        >
          <ModalNewAppointmentContainer>
            <div>
              <h6>Selecione um cliente</h6>
              <ContainerUser>
                <UserItem onClick={() => setModalSelectUser(true)}>
                  {userSelected.id ? (
                    <>
                      <img src={userSelected.avatar_url} alt="avatar_user" />
                      <p>{userSelected.username}</p>
                    </>
                  ) : (
                    <p>Clique para selecionar um usuário</p>
                  )}
                </UserItem>
              </ContainerUser>
              <h6>Selecione um prestador</h6>
              <ContainerItemSelect>
                {providers.map(item => (
                  <ProviderItem
                    onClick={() => setProviderSelectedId(item.id)}
                    selected={providerSelectedId === item.id}
                    url={item.avatar_url}
                  />
                ))}
              </ContainerItemSelect>
              <h6>Selecione os serviços</h6>
              <ServiceContainerItems>
                {allServices.map(item => (
                  <ServiceItem
                    onClick={() => {
                      const serviceAlreadySelected = servicesSelected.find(
                        serviceSelected => serviceSelected.id === item.id,
                      );
                      if (serviceAlreadySelected) {
                        setServicesSelected(
                          servicesSelected.filter(
                            serviceSelected => serviceSelected.id !== item.id,
                          ),
                        );
                      } else {
                        setServicesSelected([...servicesSelected, item]);
                      }
                    }}
                    selected={
                      !!servicesSelected.find(
                        serviceSelected => serviceSelected.id === item.id,
                      )
                    }
                  >
                    <p>{item.name}</p>
                  </ServiceItem>
                ))}
              </ServiceContainerItems>
            </div>
            <div>
              <h6>Selecione o dia</h6>
              <ContainerItemSelect>
                <Calendar>
                  {/* <DayPicker
                    weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
                    fromMonth={new Date()}
                    disabledDays={disabledDays}
                    modifiers={{
                      available: { daysOfWeek: [1, 2, 3, 4, 5, 6] },
                    }}
                    onMonthChange={handleMonthChange}
                    selectedDays={selectedDate}
                    onDayClick={handleDateChange}
                    months={[
                      'Janeiro',
                      'Fevereiro',
                      'Março',
                      'Abril',
                      'Maio',
                      'Junho',
                      'Julho',
                      'Agosto',
                      'Setembro',
                      'Outubro',
                      'Novembro',
                      'Dezembro',
                    ]}
                  /> */}
                </Calendar>
              </ContainerItemSelect>
              <h6>Horas disponiveis</h6>
              <ContainerItemSelect>
                {hours.map((hour: any) => {
                  const date = new Date();
                  date.setHours(hour.hour);
                  date.setMinutes(hour.minute);
                  if (hour.bloqued || !hour.available) {
                    return null;
                  }

                  return (
                    <HourItem
                      onClick={() => setHourSelected(hour)}
                      selected={
                        hourSelected.hour === hour.hour &&
                        hourSelected.minute === hour.minute
                      }
                    >
                      <p>{format(date, 'HH:mm')}</p>
                    </HourItem>
                  );
                })}
              </ContainerItemSelect>
            </div>
          </ModalNewAppointmentContainer>
          <Footer modal>
            <Button
              onClick={() => {
                handleNewAppointment();
              }}
            >
              Agendar
            </Button>
          </Footer>
        </Modal>
        <Modal
          title="Selecione o usuário"
          isOpen={modalSelectUser}
          onRequestClose={() => setModalSelectUser(false)}
        >
          <Form ref={formSearchUser} onSubmit={handleSubmitUser}>
            <section style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Input
                name="name"
                placeholder="Digite o nome ou o numero do usuário"
                onChange={e => handleSearchUser(e.target.value)}
              />
            </section>
          </Form>
          <ContainerSelectUser>
            {usersSearch.map(user => (
              <UserSelectItem
                selected={userSelected.id === user.id}
                onClick={() => setUserSelected(user)}
              >
                <img src={user.avatar_url} alt="" />
                <div>
                  <h5>{user.username}</h5>
                  <p>{user.phone}</p>
                </div>
              </UserSelectItem>
            ))}
          </ContainerSelectUser>
          <Footer modal>
            <div>
              <Button onClick={() => setModalCreateUser(true)}>
                Novo usuário
              </Button>
            </div>
            <Button onClick={() => setModalSelectUser(false)}>
              {' '}
              Selecionar
            </Button>
          </Footer>
        </Modal>
        <Modal
          title="Novo usuário"
          onRequestClose={() => {
            setModalCreateUser(false);
          }}
          isOpen={modalCreateUser}
          size={600}
        >
          <Form ref={formRef} onSubmit={handleCreateUser} autoComplete="off">
            <section>
              <Input
                name="username"
                placeholder="Nome do usuário"
                label="Nome (Obrigatório)"
                icon={FiUser}
              />
            </section>

            <section>
              <InputMask
                mask="phone"
                label="Celular (Obrigatório)"
                name="phone"
                placeholder="Digite o numero do celular"
                icon={IoPhonePortraitOutline}
              />
            </section>

            <section>
              <InputMask
                name="dateBirth"
                mask="date"
                label="Data de nascimento (Obrigatório)"
                placeholder="Digite a data de nascimento"
                icon={FaBirthdayCake}
              />
            </section>

            {/* <section>
              <Switch
                orientation="vertical"
                name="isAdmin"
                label="Administrador?"
              />

              <Switch
                orientation="vertical"
                name="isProvider"
                label="Prestador?"
              />
            </section> */}
            <Footer modal>
              <Button type="submit">Cadastrar</Button>
            </Footer>
          </Form>
        </Modal>
        <Modal
          title="Novo Bloqueio"
          isOpen={modalBlockHour}
          onRequestClose={() => {
            setModalBlockHour(false);
            setSelectedDate(new Date());
            setProviderSelectedId(null);
          }}
          size={500}
        >
          <ModalBlockHourContainer>
            <h6>Selecione um prestador</h6>
            <ContainerItemSelect>
              {providers.map(item => (
                <ProviderItem
                  onClick={() => setProviderSelectedId(item.id)}
                  selected={providerSelectedId === item.id}
                  url={item.avatar_url}
                />
              ))}
            </ContainerItemSelect>
            <h6>Selecione o dia</h6>
            <ContainerItemSelect>
              <Calendar>
                <DayPicker
                  // weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
                  month={new Date()}
                  // disabledDays={disabledDays}
                  // modifiers={{
                  //   available: { daysOfWeek: [1, 2, 3, 4, 5, 6] },
                  // }}
                  onMonthChange={handleMonthChange}
                  selected={selectedDate}
                  onDayClick={handleDateChange}
                  modifiersStyles={{
                    selected: {
                      color: '#ff8503',
                      background: '#343233',
                      border: 'none',
                    },
                    today: {
                      color: '#237BC3',
                    },
                  }}
                  styles={{
                    cell: {},
                    day: {
                      background: '#323234',
                      borderRadius: 10,
                      margin: 4,
                      width: 40,
                      height: 40,
                    },
                    month: {
                      width: '100%',
                      borderCollapse: 'separate',
                      borderSpacing: 8,
                      margin: 16,
                    },
                    months: {
                      width: '100%',
                    },
                  }}
                />
              </Calendar>
            </ContainerItemSelect>
            <Form ref={formBlockHourRef} onSubmit={handleCreateBlockHour}>
              <section>
                <InputMask
                  mask="hour"
                  name="startHour"
                  label="Hora Inicio"
                  placeholder="HH:mm"
                />
                <InputMask
                  mask="hour"
                  name="endHour"
                  label="Hora Fim"
                  placeholder="HH:mm"
                />
              </section>
              <section>
                <Input
                  name="reason"
                  label="Mensagem de bloqueio"
                  placeholder="Digite aqui o motivo de bloqueio"
                />
              </section>
            </Form>
            <Footer modal>
              <Button
                onClick={() => {
                  // Swal.fire({
                  //   title: 'Confirme!',
                  //   text: `Bloqueio de horário para ${
                  //     providers.find(item => item.id === providerSelectedId)
                  //       ?.text
                  //   } em ${dateSelected} das`,
                  //   icon: 'warning',
                  //   confirmButtonText: 'Sim, Bloquear horário',
                  //   confirmButtonColor: '#dc3545',
                  //   cancelButtonText: 'Fechar',
                  //   showCancelButton: true,
                  // }).then(resultCancel => {
                  //   if (resultCancel.isConfirmed) {
                  formBlockHourRef.current?.submitForm();
                  //   }
                  // });
                }}
              >
                Salvar
              </Button>
            </Footer>
          </ModalBlockHourContainer>
        </Modal>
        <Header>
          <div>
            <Button onClick={() => setModalNewAppointment(true)}>
              Novo Agendamento
            </Button>
            <Button color="danger" onClick={() => setModalBlockHour(true)}>
              Novo Bloqueio
            </Button>
          </div>
        </Header>
        {providers.length && appointments.length && (
          <ScheduleComponent
            data={[...appointments, ...bloquedTimes]}
            providers={providers}
          />
        )}
      </Spin>
    </Container>
  );
}
