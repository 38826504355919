import React from 'react';

import { Link } from 'react-router-dom';
import { FiPower } from 'react-icons/fi';
import { IoIosCut, IoIosCalendar } from 'react-icons/io';
import {
  FaBookOpen,
  FaBox,
  FaBoxOpen,
  FaChartPie,
  FaDollarSign,
  FaFileArchive,
  FaSprayCan,
  FaUsers,
} from 'react-icons/fa';
import { IoSettings } from 'react-icons/io5';
import PackageJSON from '../../../package.json';
import { Container, Options, PageHeader, Content } from './styles';

import { useAuth } from '../../hooks/auth';

import logoImg from '../../assets/logo.png';

const Menu: React.FC = ({ children }) => {
  const { user, signOut } = useAuth();

  if (!user) {
    return <>{children}</>;
  }

  return (
    <Container>
      <Options>
        <div>
          <img src={logoImg} alt="AllanHebert" />
        </div>
        {/* <Link to="/">
          <div>
            <FaChartPie />
          </div>
          <p>Dashboard</p>
        </Link> */}
        <Link to="/order">
          <div>
            <IoIosCut />
          </div>
          <p>Atendimentos</p>
        </Link>
        <Link to="/schedule/v2">
          <div>
            <IoIosCalendar />
          </div>
          <p>Nova Agenda</p>
        </Link>
        <Link to="/schedule">
          <div>
            <IoIosCalendar />
          </div>
          <p>Agenda</p>
        </Link>
        <Link to="/services">
          <div>
            <FaBookOpen />
          </div>
          <p>Serviços</p>
        </Link>
        <Link to="/users">
          <div>
            <FaUsers />
          </div>
          <p>Usuários</p>
        </Link>
        <Link to="/products">
          <div>
            <FaSprayCan />
          </div>
          <p>Produtos</p>
        </Link>
        <Link to="/reports">
          <div>
            <FaFileArchive />
          </div>
          <p>Relatórios</p>
        </Link>
        <Link to="/configbarberhour">
          <div>
            <IoSettings />
          </div>
          <p>Configuração</p>
        </Link>
        {/* <Link to="/reports">
          <div>
            <FaBox />
          </div>
          <p>Entradas</p>
        </Link>
        <Link to="/reports">
          <div>
            <FaBoxOpen />
          </div>
          <p>Retiradas</p>
        </Link> */}
        <Link to="/cashregister">
          <div>
            <FaDollarSign />
          </div>
          <p>Caixa</p>
        </Link>
      </Options>
      <div
        style={{ display: 'flex', overflow: 'auto', flexDirection: 'column' }}
      >
        <PageHeader>
          <div>
            {/* <span>Bem-vindo,</span> */}
            <p>v {PackageJSON.version}</p>
            <Link to="/profile">
              <strong>{user?.username}</strong>
            </Link>
            <img src={user?.avatar_url} alt={user?.username || 'user'} />
            <button type="button" onClick={signOut}>
              <FiPower />
            </button>
          </div>{' '}
        </PageHeader>
        <Content>{children}</Content>
      </div>
    </Container>
  );
};

export default Menu;
