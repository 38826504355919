export default {
  phone: '(99) 99999-9999',
  cpf: '999.999.999-99',
  pis: '999.99999.99-9',
  code: '9999',
  zipCode: '99999-999',
  hour: '99:99',
  minutes: '999:99',
  month: '99',
  year: '9999',
  date: '99/99/9999',
  percent: '999',
  monthyear: '99/9999',
};
