import React, { useState } from 'react';
import {
  ViewState,
  GroupingState,
  IntegratedGrouping,
} from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  DayView,
  Appointments,
  MonthView,
  Toolbar,
  DateNavigator,
  ViewSwitcher,
  TodayButton,
  Resources,
  AppointmentTooltip,
  GroupingPanel,
} from '@devexpress/dx-react-scheduler-material-ui';
import {
  withStyles,
  Theme,
  createStyles,
  StyledProps,
  StyleRules,
} from '@material-ui/core';
import { indigo, blue, teal } from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { WithStyles } from '@material-ui/styles';
import classNames from 'clsx';
import { ImCancelCircle, ImWhatsapp } from 'react-icons/im';

import { FiUserX } from 'react-icons/fi';
import Swal from 'sweetalert2';
import {
  ProviderContainer,
  AppointmentItemSchedule,
  AppoitmentItemToolTip,
} from '../styles';
import api from '../../../services/api';
import { Spin } from '../../../components/Spin';
import { useToast } from '../../../hooks/toast';
import Dropdown from '../../../components/Dropdown';

const allDayLocalizationMessages = {
  'pt-Br': {
    allDay: 'Todos os dias',
    today: 'Hoje',
  },
};

const styles = ({ palette }: Theme): StyleRules =>
  createStyles({
    appointment: {
      borderRadius: 0,
      borderBottom: 0,
    },
    highPriorityAppointment: {
      borderLeft: `4px solid ${teal[500]}`,
    },
    mediumPriorityAppointment: {
      borderLeft: `4px solid ${blue[500]}`,
    },
    lowPriorityAppointment: {
      borderLeft: `4px solid ${indigo[500]}`,
    },
    weekEndCell: {
      backgroundColor: fade(palette.action.disabledBackground, 0.04),
      '&:hover': {
        backgroundColor: fade(palette.action.disabledBackground, 0.04),
      },
      '&:focus': {
        backgroundColor: fade(palette.action.disabledBackground, 0.04),
      },
    },
    weekEndDayScaleCell: {
      backgroundColor: fade(palette.action.disabledBackground, 0.06),
    },
    text: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    content: {
      opacity: 0.7,
    },
    container: {
      width: '100%',
      lineHeight: 1.2,
      height: '100%',
    },
  });

type AppointmentProps = Appointments.AppointmentProps &
  WithStyles<typeof styles>;
type AppointmentContentProps = Appointments.AppointmentContentProps &
  WithStyles<typeof styles>;
type TimeTableCellProps = MonthView.TimeTableCellProps &
  WithStyles<typeof styles>;
type DayScaleCellProps = MonthView.DayScaleCellProps &
  WithStyles<typeof styles>;

const isWeekEnd = (date: Date): boolean =>
  date.getDay() === 0 || date.getDay() === 6;
const defaultCurrentDate = new Date();

const DayScaleCell = withStyles(styles)(
  ({ startDate, classes, ...restProps }: DayScaleCellProps) => (
    <MonthView.DayScaleCell
      className={classNames({
        [classes.weekEndDayScaleCell]: isWeekEnd(startDate),
      })}
      startDate={startDate}
      {...restProps}
    />
  ),
);

const TimeTableCell = withStyles(styles)(
  ({ startDate, classes, ...restProps }: TimeTableCellProps) => (
    <MonthView.TimeTableCell
      className={classNames({
        [classes.weekEndCell]: isWeekEnd(startDate!),
      })}
      startDate={startDate}
      {...restProps}
    />
  ),
);

const Appointment = withStyles(styles)(
  ({ classes, data, ...restProps }: AppointmentProps) => (
    <Appointments.Appointment
      {...restProps}
      className={classNames({
        [classes.highPriorityAppointment]: data.priority === 1,
        [classes.mediumPriorityAppointment]: data.priority === 2,
        [classes.lowPriorityAppointment]: data.priority === 3,
        [classes.appointment]: true,
      })}
      data={data}
    />
  ),
);

interface Option {
  title: string;
  provider_name: string;
  startDate: Date;
  endDate: Date;
  location: string;
  id: string;
}

interface Provider {
  id: string;
  text: string;
  color: string;
  avatar_url: string;
}

interface Props {
  data: Option[];
  providers: Provider[];
}

export default function ScheduleComponent({
  data = [],
  providers = [],
}: Props): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [appointments, setAppointments] = useState(data);

  const { addToast } = useToast();

  function renderAvatar(provider_id: string): string {
    const provider = providers.find(item => item.id === provider_id);

    if (provider) {
      return provider.avatar_url;
    }
    return '';
  }

  const AppointmentContent = withStyles(styles, { name: 'AppointmentContent' })(
    ({ data: dataAppointment, ...restProps }: AppointmentContentProps) => {
      const index = appointments.findIndex(
        item => item.id === dataAppointment.id,
      );
      console.log(index);

      const zIndex = Math.floor(99999999 * (index + 1));

      return (
        <Appointments.AppointmentContent
          {...restProps}
          data={dataAppointment}
          style={{ height: '100%', background: 'tomato', padding: 0, zIndex }}
        >
          <AppointmentItemSchedule color={dataAppointment.status_color}>
            {dataAppointment.user_avatar ? (
              <img src={dataAppointment.user_avatar} alt="user_avatar" />
            ) : (
              <div />
            )}

            <div>
              <h2>
                {dataAppointment.title} -{' '}
                <span style={{ color: dataAppointment.status_color }}>
                  {dataAppointment.status_name}
                </span>
              </h2>
              <p>{dataAppointment.services}</p>
            </div>
            <Dropdown
              placement="bottom"
              options={[
                {
                  title: `Conversar com ${dataAppointment.title}`,
                  onClick: () => {
                    window.open(`https://wa.me/55${dataAppointment.phone}`);
                  },
                  Icon: <ImWhatsapp />,
                },
                {
                  title: `${dataAppointment.title} Faltou`,
                  onClick: () => {
                    Swal.fire({
                      title: 'Confirme!',
                      text: `Confirma que ${dataAppointment.title} não compareceu ao atendimento?`,
                      icon: 'warning',
                      confirmButtonText: 'Confirmar',
                      confirmButtonColor: '#dc3545',
                      showCloseButton: true,
                      cancelButtonText: 'Fechar',
                      showCancelButton: true,
                      showLoaderOnConfirm: true,
                      focusConfirm: true,
                    }).then((result: any) => {
                      if (result.isConfirmed) {
                        handleCostumerMissedAppointment(dataAppointment.id);
                      }
                    });
                  },
                  Icon: <FiUserX />,
                },
                {
                  title: 'Cancelar Agendamento',
                  onClick: () => {
                    Swal.fire({
                      title: 'Confirme!',
                      text: 'Deseja realmente cancelar esse agendamento?',
                      icon: 'warning',
                      confirmButtonText: 'Confirmar cancelamento',
                      confirmButtonColor: '#dc3545',
                      showCloseButton: true,
                      cancelButtonText: 'Fechar',
                      showCancelButton: true,
                      showLoaderOnConfirm: true,
                      focusConfirm: true,
                    }).then((result: any) => {
                      if (result.isConfirmed) {
                        handleCancelAppointment(dataAppointment.id);
                      }
                    });
                  },
                  Icon: <ImCancelCircle />,
                },
                {
                  title: 'Cancelar Bloqueio',
                  onClick: () => {
                    Swal.fire({
                      title: 'Confirme!',
                      text: 'Deseja realmente cancelar esse bloqueio?',
                      icon: 'warning',
                      confirmButtonText: 'Confirmar cancelamento',
                      confirmButtonColor: '#dc3545',
                      showCloseButton: true,
                      cancelButtonText: 'Fechar',
                      showCancelButton: true,
                      showLoaderOnConfirm: true,
                      focusConfirm: true,
                    }).then((result: any) => {
                      if (result.isConfirmed) {
                        handleCancelBloquedTime(dataAppointment.id);
                      }
                    });
                  },
                  Icon: <ImCancelCircle />,
                  isVisible: dataAppointment.title === 'Bloqueio',
                },
              ]}
            />
          </AppointmentItemSchedule>
        </Appointments.AppointmentContent>
      );
    },
  );

  async function handleCancelAppointment(appointment_id: any): Promise<void> {
    try {
      setLoading(true);
      await api.post(`/appointments/cancel/${appointment_id}`);

      addToast({
        type: 'success',
        title: '',
        description: ' Agendamento cancelado com sucesso!',
      });

      setAppointments(
        appointments.filter((item: any) => item.id !== appointment_id),
      );
    } catch (err) {
      // Alert.alert('Opss...', 'Não foi possivel cancelar esse agendamento!');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  async function handleCostumerMissedAppointment(
    appointment_id: any,
  ): Promise<void> {
    try {
      setLoading(true);
      const response = await api.post(`/appointments/costumerMissed`, {
        appointment_id,
      });

      addToast({
        type: 'success',
        title: '',
        description: ' Agendamento modificado com sucesso!',
      });

      setAppointments(
        appointments.map((item: any) =>
          item.id === appointment_id
            ? { ...item, status_color: response.data?.status?.color }
            : item,
        ),
      );
    } catch (err: any) {
      addToast({
        type: 'error',
        title: 'Opss...',
        description:
          err.data.message || 'Não foi possivel modificar esse agendamento!',
      });
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  async function handleCancelBloquedTime(bloquedId: any): Promise<void> {
    try {
      setLoading(true);
      const response = await api.delete(`provider/bloqued/${bloquedId}`);

      if (response.data) {
        addToast({
          type: 'success',
          title: '',
          description: 'Bloqueio removido com sucesso!',
        });
        setAppointments(
          appointments.filter((item: any) => item.id !== bloquedId),
        );
      }
    } catch (err: any) {
      addToast({
        type: 'error',
        title: '',
        description:
          err?.data?.message || 'Não foi possivel remover esse bloqueio!',
      });
    } finally {
      setLoading(false);
    }
  }

  const resources = [
    {
      fieldName: 'provider',
      title: 'Prestador',
      instances: providers,
    },
  ];

  let numberCalc = 1;

  return (
    <Spin isVisible={loading}>
      <Paper>
        <Scheduler data={appointments} locale="pt-Br">
          <ViewState defaultCurrentDate={defaultCurrentDate} />

          <GroupingState
            grouping={[
              {
                resourceName: 'provider',
              },
            ]}
          />

          <DayView
            displayName="Semana"
            startDayHour={8}
            endDayHour={24}
            intervalCount={1}
          />
          {/* <MonthView
            displayName="Mês"
            dayScaleCellComponent={DayScaleCell}
            timeTableCellComponent={TimeTableCell}
          /> */}

          <Appointments
            containerComponent={({ children, style, ...rest }, index) => {
              // const index = appointments.findIndex(
              //   item => item.id === dataAppointment.id,
              // );

              const zIndex = Math.floor(99999999 - numberCalc);
              numberCalc += 1;

              return <div style={{ ...style, zIndex }}>{children}</div>;
            }}
            appointmentComponent={({ data: dataAppointment }) => {
              return (
                <AppointmentItemSchedule color={dataAppointment.status_color}>
                  {dataAppointment.user_avatar ? (
                    <img src={dataAppointment.user_avatar} alt="user_avatar" />
                  ) : (
                    <div />
                  )}

                  <div>
                    <h2>
                      {dataAppointment.title} -{' '}
                      <span style={{ color: dataAppointment.status_color }}>
                        {dataAppointment.status_name}
                      </span>
                    </h2>
                    <p>{dataAppointment.services}</p>
                  </div>
                  <Dropdown
                    placement="bottom"
                    options={[
                      {
                        title: `Conversar com ${dataAppointment.title}`,
                        onClick: () => {
                          window.open(
                            `https://wa.me/55${dataAppointment.phone}`,
                          );
                        },
                        Icon: <ImWhatsapp />,
                        isVisible: dataAppointment.title !== 'Bloqueado',
                      },
                      {
                        title: `${dataAppointment.title} Faltou`,
                        onClick: () => {
                          Swal.fire({
                            title: 'Confirme!',
                            text: `Confirma que ${dataAppointment.title} não compareceu ao atendimento?`,
                            icon: 'warning',
                            confirmButtonText: 'Confirmar',
                            confirmButtonColor: '#dc3545',
                            showCloseButton: true,
                            cancelButtonText: 'Fechar',
                            showCancelButton: true,
                            showLoaderOnConfirm: true,
                            focusConfirm: true,
                          }).then((result: any) => {
                            if (result.isConfirmed) {
                              handleCostumerMissedAppointment(
                                dataAppointment.id,
                              );
                            }
                          });
                        },
                        Icon: <FiUserX />,
                        isVisible: dataAppointment.title !== 'Bloqueado',
                      },
                      {
                        title: 'Cancelar Agendamento',
                        onClick: () => {
                          Swal.fire({
                            title: 'Confirme!',
                            text: 'Deseja realmente cancelar esse agendamento?',
                            icon: 'warning',
                            confirmButtonText: 'Confirmar cancelamento',
                            confirmButtonColor: '#dc3545',
                            showCloseButton: true,
                            cancelButtonText: 'Fechar',
                            showCancelButton: true,
                            showLoaderOnConfirm: true,
                            focusConfirm: true,
                          }).then((result: any) => {
                            if (result.isConfirmed) {
                              handleCancelAppointment(dataAppointment.id);
                            }
                          });
                        },
                        Icon: <ImCancelCircle />,
                        isVisible: dataAppointment.title !== 'Bloqueado',
                      },
                      {
                        title: 'Cancelar Bloqueio',
                        onClick: () => {
                          Swal.fire({
                            title: 'Confirme!',
                            text: 'Deseja realmente cancelar esse bloqueio?',
                            icon: 'warning',
                            confirmButtonText: 'Confirmar cancelamento',
                            confirmButtonColor: '#dc3545',
                            showCloseButton: true,
                            cancelButtonText: 'Fechar',
                            showCancelButton: true,
                            showLoaderOnConfirm: true,
                            focusConfirm: true,
                          }).then((result: any) => {
                            if (result.isConfirmed) {
                              handleCancelBloquedTime(dataAppointment.id);
                            }
                          });
                        },
                        Icon: <ImCancelCircle />,
                        isVisible: dataAppointment.title === 'Bloqueado',
                      },
                    ]}
                  />
                </AppointmentItemSchedule>
              );
            }}
            appointmentContentComponent={() => <p>teste</p>}
          />
          <Resources data={resources} />

          <IntegratedGrouping />

          {/* <AppointmentTooltip
          showCloseButton
          showDeleteButton
          showOpenButton
          contentComponent={() => (
            <AppoitmentItemToolTip>
              <p>teste</p>
            </AppoitmentItemToolTip>
          )}
        /> */}
          <GroupingPanel
            cellComponent={({ group, ...rest }: any) => (
              <ProviderContainer>
                <div>
                  <img src={renderAvatar(group.id)} alt="provider_img" />
                  <h2>{group.text}</h2>
                </div>
              </ProviderContainer>
            )}
          />
          <Toolbar />
          <DateNavigator />
          <ViewSwitcher />
          <TodayButton messages={allDayLocalizationMessages['pt-Br']} />
        </Scheduler>
      </Paper>
    </Spin>
  );
}
