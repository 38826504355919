import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.padding}px 0;

  svg {
    height: 100px;
  }

  span {
    margin: 8px 0;
  }
`;
