import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';

import Profile from '../pages/Profile';
import Dashboard from '../pages/Dashboard';
import Services from '../pages/Services';
import Products from '../pages/Products';
import User from '../pages/User';
import Order from '../pages/Order';
import OrderDetails from '../pages/Order/Details';
import Reports from '../pages/Reports';
import ReportOrders from '../pages/Reports/Orders';
import ReportComission from '../pages/Reports/Comission';
import Schedule from '../pages/Schedule';
import CashResgister from '../pages/CashResgister';
import NewSchedule from '../pages/NewSchedule';
import ConfigBarberHour from '../pages/ConfigBarberHour';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />

    <Route path="/signup" component={SignUp} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password" component={ResetPassword} />

    <Route path="/profile" component={Profile} isPrivate />
    <Route path="/dashboard" component={Dashboard} isPrivate>
      <Redirect to="/schedule/v2" />
    </Route>
    <Route path="/services" component={Services} isPrivate />
    <Route path="/products" component={Products} isPrivate />
    <Route path="/users" component={User} isPrivate />
    <Route exact path="/order" component={Order} isPrivate />
    <Route exact path="/order/:id" component={OrderDetails} isPrivate />
    <Route exact path="/reports" component={Reports} isPrivate />
    <Route exact path="/reports/orders" component={ReportOrders} isPrivate />
    <Route
      exact
      path="/reports/commission"
      component={ReportComission}
      isPrivate
    />
    <Route exact path="/schedule" component={Schedule} isPrivate />
    <Route exact path="/schedule/v2" component={NewSchedule} isPrivate />
    <Route exact path="/cashregister" component={CashResgister} isPrivate />
    <Route
      exact
      path="/configbarberhour"
      component={ConfigBarberHour}
      isPrivate
    />
  </Switch>
);

export default Routes;
